import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const imageCta = document.querySelectorAll('body:not(.wp-admin) .pane--cta .image-gallery');

if (imageCta) {
    document.addEventListener('DOMContentLoaded', () => {
        if (window.matchMedia('(min-width: 992px)').matches) {
            const images = document.querySelectorAll('.image');

            images.forEach((image, index) => {
                // Calculate staggered offset for each image
                const offset = 15 + index * 15; // Increase the offset per image

                gsap.fromTo(
                    image,
                    { y: offset },
                    {
                        y: -offset,
                        scrollTrigger: {
                            trigger: ".image-gallery",
                            start: "top bottom",
                            end: "bottom top",
                            scrub: 0.5,
                            markers: false,
                        }
                    }
                );
            });
        }
    });
}

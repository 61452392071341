class reservation {
    constructor() {
        this.reservationButton = document.querySelector("[data-type='reservation']");
        this.iframeContainer = document.querySelector('[iframe-container]');

        if (this.reservationButton && this.iframeContainer) {
            this.init();
        }
    }

    init() {
        this.reservationButton.addEventListener("click", this.openIframe(this.iframeContainer));
    }

    openIframe(iframeContainer) {
        this.iframeContainer = iframeContainer;
        iframeContainer.style.display = 'block'; // Show the iframe container
    }
}

if (!document.body.classList.contains("wp-admin")) {
    new reservation();
}

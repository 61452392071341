class Scrolley {
    constructor() {
        this.scrolley = document.querySelector("[data-scrolley]");

        if (this.scrolley) {
            this.init();
        }
    }

    init() {
        this.scrolley.addEventListener("click", this.scrollTo);
    }

    scrollTo() {
        window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
        });
    }
}

if (!document.body.classList.contains("wp-admin")) {
    new Scrolley();
}

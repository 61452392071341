import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, FreeMode, EffectFade } from 'swiper/modules';
import { swiperSizes, swiperOptions, effects } from "../config/swiperConfig";
import { breakpoints } from "../core/constants";

function createSwiperInstance(container, config = {}) {
    const slideSize = container.getAttribute('slide-width');
    const showControls = container.getAttribute('show-controls');
    const showPagination = container.getAttribute('show-pagination');

    config = {
        ...config,
        allowTouchMove: showControls ? false : true,
        grabCursor: showControls ? false : true,
        breakpoints: {
            [`${breakpoints.lg}`]: {
                slidesPerView: swiperSizes[slideSize],
            }
        }
    };

    return new Swiper(container, {
        ...{
            modules: [
                EffectFade,
                Navigation,
                Pagination,
                Autoplay,
                FreeMode,
            ],
        },
        ...swiperOptions,

        ...{
            // Navigation arrows
            navigation: {
                nextEl: container.closest(".pane").querySelector(".swiper__button--next"),
                prevEl: container.closest(".pane").querySelector(".swiper__button--prev"),
                disabledClass: 'swiper__button--disabled'
            },
            pagination: showPagination == 'true' ? {
                el: '.swiper__pagination',
                type: 'bullets',
                clickable: true,
                bulletClass: 'swiper__bullet',
                bulletActiveClass: 'is-active'
            } : false,
        },
        ...config,
    });
}

const swipers = [...document.querySelectorAll('.swiper:not(.swiper--logo)')];
swipers.forEach(swiper => {
    const config = {
        slidesPerView: 1.1,
    };
    createSwiperInstance(swiper, config);
});

const contentSwipers = [...document.querySelectorAll('.pane--highlighted_content .swiper')];
contentSwipers.forEach(swiper => {
    const config = {
        slidesPerView: 1.1,
    };
    createSwiperInstance(swiper, config);
});

const logoSwipers = [...document.querySelectorAll('.swiper--logo')];

logoSwipers.forEach(swiper => {
    const config = {
        slidesPerView: 2.5,
        centeredSlides: true,

        breakpoints: {
            [`${breakpoints.lg}`]: {
                enabled: false,
                grabCursor: false,
            }
        }
    };
    createSwiperInstance(swiper, config);
})

const reviewSwipers = [...document.querySelectorAll('.swiper--review')];
reviewSwipers.forEach(swiper => {
    const config = {
        autoplay: true,
        slidesPerView: 1,
        loop: true,
        initialSlide: 2,

        breakpoints: {
            [`${breakpoints.lg}`]: {
                slidesPerView: 2.5,
            }
        }
    };
    createSwiperInstance(swiper, config);
})

const heroSwipers = [...document.querySelectorAll('.swiper--hero')];

heroSwipers.forEach(swiper=> {
    const config = {
        grabCursor: false,
        allowTouchMove: false,
        simulateTouch: false,
        speed: '750',
        effect: effects[1],
        fadeEffect: {
            crossFade: true, // Optional: Enables crossfade transition
        },
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper__pagination',
            clickable: true,
            renderBullet: function (index, className) {
                const slideName = swiper.querySelectorAll('.swiper__slide')[index]?.getAttribute('slide-name') || '';
                return '<span class="' + className + '">'+ slideName +'</span>';
            },
        },
    };
    createSwiperInstance(swiper, config);
});
